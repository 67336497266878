<chayns-tapp [plugin]="true">
  <chayns-tapp-intro>
    <h1>Shop Auswertung</h1>
  </chayns-tapp-intro>
  <chayns-tapp-content>
    <chayns-accordion *ngFor="let month of months; let lastMonth = last;"
                      [headTitle]="monthNames[month.month] + ' ' + month.year">
      <chayns-acc-head-right>
        <div class="badge">
          <span>{{month.totalSales | currency:'EUR'}}</span>
        </div>
      </chayns-acc-head-right>
      <chayns-accordion-item>
        <div class="export" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column">
            <div>
              <strong>CSV Export</strong>
            </div>
            <div>
              <input type="checkbox" class="checkbox" id="exportEuro" [(ngModel)]="exportEuroSymbol">
              <label for="exportEuro">Mit Euro-Zeichen</label>
            </div>
            <div>
              <input type="checkbox" class="checkbox" id="exportSum" [(ngModel)]="exportSum">
              <label for="exportSum">Mit Summenzeile</label>
            </div>
          </div>
          <div class="download" title="CSV" (click)="downloadCsv(month)">
            <i class="fas fa-file-csv"></i>
          </div>
        </div>
      </chayns-accordion-item>
      <chayns-accordion-item *ngFor="let article of month.articles">
        <span>{{article.name}}</span>
        <div class="flex"></div>
        <div class="right badge">
          <span>{{article.amount}} x {{article.price | currency:'EUR'}} = {{article.sales | currency:'EUR'}}</span>
        </div>
      </chayns-accordion-item>
    </chayns-accordion>
    <div style="text-align: center; margin-top: 15px;" *ngIf="isLoading">
      <i>Die Daten vom {{ getNextMonthName() }} werden geladen...</i>
    </div>
    <div style="text-align: center; margin-top: 15px;" *ngIf="!isLoading">
      <button chaynsButton
              (click)="loadNextMonth()">{{ getNextMonthName() }} laden
      </button>
    </div>

  </chayns-tapp-content>
</chayns-tapp>
