import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { Month } from './tsc/month';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading: boolean;
  months: Month[];
  monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  exportEuroSymbol: boolean;
  exportSum: boolean;

  constructor(private http: HttpClient, private currency: CurrencyPipe) {
    this.months = [];
    this.isLoading = true;
  }

  ngOnInit(): void {
    const date = new Date();
    this.loadBookingMonth(date.getMonth(), date.getFullYear());
  }

  private loadBookingMonth(month: number, year: number): void {
    this.isLoading = true;
    chayns.showWaitCursor();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${chayns.env.user.tobitAccessToken}`
      })
    };
    this.http
      .get<Month>(
        `https://us-central1-mindtraffic-f7bcc.cloudfunctions.net/ecommerceApp/api/location/${chayns.env.site.locationPersonId}/month?month=${month}&year=${year}`,
        httpOptions
      )
      .subscribe(bookingMonth => {
        this.isLoading = false;
        console.log('get booking month', bookingMonth);
        this.months.push(bookingMonth);
        chayns.hideWaitCursor();
      }, err => {
        console.error(err);
        this.isLoading = false;
        chayns.hideWaitCursor();
      });
  }

  public loadNextMonth(): void {
    const lastMonth = this.months[this.months.length - 1];
    let month = lastMonth.month - 1;
    let year = lastMonth.year;
    if (month < 0) {
      month = 11;
      year--;
    }
    this.loadBookingMonth(month, year);
  }

  downloadCsv(month: Month) {
    const rows: string[][] = [];
    rows.push([`Umsaetze - ${this.monthNames[month.month]} ${month.year}`]);
    rows.push(['Artikel', 'Verkaeufe', 'Preis', 'Umsatz']);
    for (const article of month.articles) {
      const row = [
        article.name,
        article.amount.toString()
      ];

      if (this.exportEuroSymbol) {
        row.push(
          this.currency.transform(article.price, 'EUR'),
          this.currency.transform(article.sales, 'EUR')
        );
      } else {
        row.push(
          article.price.toString().replace('.', ','),
          article.sales.toString().replace('.', ','))
        ;
      }

      rows.push(row);
    }

    // add sum row
    if (this.exportSum) {
      if (this.exportEuroSymbol) {
        rows.push(['Summe', '', '', this.currency.transform(month.totalSales, 'EUR')]);
      } else {
        rows.push(['Summe', '', '', month.totalSales.toString().replace('.', ',')]);
      }
    }

    let csvContent = 'data:text/csv;charset=utf-8,';
    rows.forEach(rowArray => {
      const row = rowArray.join(';');
      csvContent += row + '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Umsaetze - ${this.monthNames[month.month]} ${month.year}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  public getNextMonthName(): string {
    if (this.months.length === 0) {
      const date = new Date();
      return this.monthNames[date.getMonth()];
    }
    return this.monthNames[this.months[this.months.length - 1].month - 1] || this.monthNames[11];
  }
}
